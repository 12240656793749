import {
  Component,
  forwardRef,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
} from '@angular/core';
import { IonCheckbox } from '@ionic/angular/standalone';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Represents a checkbox group component that implements ControlValueAccessor and AfterContentInit interfaces.
 * This component allows multiple checkboxes to be grouped together and managed as a single value.
 *
 * @example
 * // In your template:
 * <app-hes-checkbox-group [(ngModel)]="selectedValues" [readonly]="true">
 *    <ion-checkbox value="option1">Option 1</ion-checkbox>
 *    <ion-checkbox value="option2">Option 2</ion-checkbox>
 *    <ion-checkbox value="option3">Option 3</ion-checkbox>
 * </app-hes-checkbox-group>
 *
 * // In your component:
 * selectedValues: string[] = [];
 */
@Component({
  selector: 'app-hes-checkbox-group',
  template: `<ng-content></ng-content>`,
  standalone: true,
  imports: [IonCheckbox],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HesCheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class HesCheckboxGroupComponent
  implements ControlValueAccessor, AfterContentInit
{
  @ContentChildren(IonCheckbox, { read: IonCheckbox, descendants: true })
  checkboxes: QueryList<IonCheckbox>;

  // Add an input property for readonly. When true, the checkboxes will be disabled and won't update the value.
  @Input() readonly: boolean = false;

  // Track the disabled state from the form control
  disabled: boolean = false;

  value: any[] = [];

  writeValue(value: any[] | null): void {
    if (value && value.length) {
      this.value = [...value];
      this.checkboxes?.forEach((checkbox) => {
        checkbox.checked = this.value.includes(checkbox.value);
      });
    } else {
      this.value = [];
      this.checkboxes?.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.checkboxes) {
      this.checkboxes.forEach((checkbox) => {
        // Disable if either the form control is disabled or readonly is true.
        checkbox.disabled = isDisabled || this.readonly;
      });
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngAfterContentInit() {
    this.checkboxes?.forEach((checkbox) => {
      // Initialize the checkbox state
      checkbox.checked = this.value.includes(checkbox.value);
      // Set the disabled state based on form control disabled or readonly flag
      checkbox.disabled = this.disabled || this.readonly;
      // Subscribe to changes
      checkbox.ionChange.subscribe((event: CustomEvent) => {
        // Ignore changes if readonly is true
        if (this.readonly) {
          return;
        }
        this.updateValue(event, checkbox.value);
      });
    });
    // Update checkboxes on changes in the content
    this.checkboxes?.changes.subscribe(() => {
      this.checkboxes?.forEach((checkbox) => {
        checkbox.checked = this.value.includes(checkbox.value);
        checkbox.disabled = this.disabled || this.readonly;
      });
    });
  }

  updateValue(event: CustomEvent, value: any) {
    // Do not update value if readonly is true
    if (this.readonly) {
      return;
    }

    if (event.detail.checked) {
      this.value.push(value);
    } else {
      this.value = this.value.filter((v) => v !== value);
    }
    this.onChange([...this.value]);
    this.onTouched();
  }
}
